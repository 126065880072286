import React, { Fragment } from 'react';
import BackButton from '../../parts/BackButton';

import poster from '../../../assets/images/poster/poster.jpg';

const Info: React.FC = (props: any) => {
    return (
      <Fragment>
        <BackButton text="" />
        <main>
          <div className="container info">
            <div className="info-video">
              <video controls poster={poster}>
                <source src={require('../../../assets/videos/kletsborden_temp.mp4')} type="video/mp4" />
              </video>
            </div>
            Kletsborden is gemaakt door de HAN University of Applied Sciences, de Werkplaats Sociaal Domein Nijmegen, Kenniscentrum HAN SOCIAAL, Kenniscentrum Technologie en Samenleving, <a href="https://www.sannekeduijf.com/" target="_BLANK" rel="noopener noreferrer">Solution Office</a>,
            in samenwerking met <a href="https://www.semmie.net/" target="_BLANK" rel="noopener noreferrer">Semmie Sprekend Spel </a>en <a href="https://b302.nl" target="_BLANK" rel="noopener noreferrer">B302</a>.
            <br /><br />
            Voor meer info over het HAN-onderzoek: <br />
            <a href="https://blog.han.nl/werkplaatssociaaldomeinnijmegen/kletsborden" target="_BLANK" rel="noopener noreferrer">https://blog.han.nl/werkplaatssociaaldomeinnijmegen/kletsborden</a>
          </div>
        </main>
      </Fragment>
    )
}

export default Info